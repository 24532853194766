.RouteLoading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .loader {
        width: 50px;
        height: 50px;
    }

    .loader:before {
        content: "";
        width: 50px;
        height: 5px;
        border-radius: 50%;
        background: #000;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        animation: routeShadow .5s linear infinite;
    }

    .loader:after {
        content: "";
        width: 50px;
        height: 50px;
        border-radius: 3px;
        background: #319795;
        position: absolute;
        top: 0;
        left: 0;
        animation: routeLoading .5s linear infinite;
    }

    @keyframes routeLoading {
        17% {
            border-bottom-right-radius: 3px;
        }

        25% {
            transform: translateY(9px) rotate(22.5deg);
        }

        50% {
            transform: translateY(18px) scale(1, 0.9) rotate(45deg);
            border-bottom-right-radius: 40px;
        }

        75% {
            transform: translateY(9px) rotate(67.5deg);
        }

        100% {
            transform: translateY(0) rotate(90deg);
        }
    }

    @keyframes routeShadow {

        0%,
        100% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1.2, 1);
        }
    }
}